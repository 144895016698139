import React, { useEffect, useState } from 'react'
import { createAttendance } from '../../../services/AttendanceService'
import LoadingDialog from '../../../helper/LoadingDialog'
import ResponseDialog from '../../../helper/ResponseDialog'
import ConfirmationDialog from '../clock-in-out/ConfirmationDialog'
import { getEmployeeTimeOutWithToken } from '../../../services/EmployeeService'

const Transaction = {
    TIME_IN: 1,
    TIME_OUT: 2
}

const AttendanceActions = () => {
    const [selectedLocation, setSelectedLocation] = useState('HOME')
    const [currentTime, setCurrentTime] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [overtimeMessage, setOvertimeMessage] = useState('')

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 1000)
        return () => clearInterval(timer)
    }, [])

    const formatTime = currentTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    })
    const formatDate = currentTime.toLocaleDateString([], {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value)
    }

    const handleAttendanceResponse = async (attendance) => {
        setLoading(true)
        try {
            const result = await createAttendance(attendance)
            setResponseMessage(result.error ? result.error : result.message)
        } catch {
            setResponseMessage('An error occurred while recording attendance.')
        } finally {
            setLoading(false)
            setShowResponseDialog(true)
        }
    }

    const handleClockAction = async (transaction, reason = '') => {
        const attendance = {
            transaction,
            remarks: selectedLocation === 'HOME' ? 1 : 2,
            isOvertime: transaction === 2 && reason !== '',
            reason
        }
        await handleAttendanceResponse(attendance)
    }

    const checkOvertime = async () => {
        const result = await getEmployeeTimeOutWithToken()
        const timeOut = parseInt(result.timeOut, 10)
        const current = new Date()
        const defaultTimeOut = new Date(current).setHours(timeOut, 0, 0, 0)
        console.log('result', result)

        if (current > defaultTimeOut) {
            const overtimeDuration = Math.floor((current - defaultTimeOut) / (1000 * 60))
            // if (overtimeDuration >= 1) {
            const overtimeHours = Math.floor(overtimeDuration / 60)
            const overtimeMinutes = overtimeDuration % 60
            setOvertimeMessage(
                `Please confirm: Do you have ${overtimeHours} hour/s and ${overtimeMinutes} minutes of overtime? If yes, please enter the reason for the overtime.`
            )
            setIsModalOpen(true)
            // } else {
            //     handleClockAction(Transaction.TIME_OUT)
            // }
        } else {
            handleClockAction(Transaction.TIME_OUT)
        }
    }

    const handleConfirm = (reason) => {
        setIsModalOpen(false)
        handleClockAction(Transaction.TIME_OUT, reason)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        handleClockAction(Transaction.TIME_OUT)
    }

    return (
        <div className="flex flex-col h-full overflow-auto">
            <div className="flex-1 min-w-[320px] p-4">
                <h3 className="text-2xl font-semibold">Clock In / Out</h3>
                <div className="p-6 text-center">
                    <p className="text-md font-semibold text-red-700">{formatDate}</p>
                    <h3 className="text-6xl font-bold p-4">{formatTime}</h3>
                </div>
                <div className="p-4 flex space-x-4 items-center justify-center overflow-x-auto">
                    {['HOME', 'OFFICE'].map((location) => (
                        <label
                            key={location}
                            className={`flex items-center text-xl px-4 py-2 rounded ${
                                selectedLocation === location
                                    ? location === 'HOME'
                                        ? 'bg-red-300'
                                        : 'bg-green-300'
                                    : 'bg-transparent'
                            }`}
                        >
                            <input
                                type="radio"
                                value={location}
                                checked={selectedLocation === location}
                                onChange={handleLocationChange}
                                className="mr-2"
                            />
                            {location}
                        </label>
                    ))}
                </div>
                <div className="p-4 flex space-x-4 justify-center">
                    <button
                        onClick={() => handleClockAction(Transaction.TIME_IN)}
                        className="bg-blue-500 text-white px-6 py-2 w-40 rounded shadow hover:bg-blue-600 text-xl"
                    >
                        TIME IN
                    </button>
                    <button
                        onClick={checkOvertime}
                        className="bg-red-500 text-white px-6 py-2 w-40 rounded shadow hover:bg-red-600 text-xl"
                    >
                        TIME OUT
                    </button>
                </div>
                <LoadingDialog loading={loading} />
                <ResponseDialog
                    open={showResponseDialog}
                    onClose={() => setShowResponseDialog(false)}
                    responseMessage={responseMessage}
                />
                <ConfirmationDialog
                    isOpen={isModalOpen}
                    message={overtimeMessage}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            </div>
        </div>
    )
}

export default AttendanceActions
