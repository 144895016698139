// src/components/QRAttendanceActions.jsx
import React, { useEffect, useState } from 'react'
import { createQRAttendance } from '../../../services/AttendanceService'
import LoadingDialog from '../../../helper/LoadingDialog'
import ResponseDialog from '../../../helper/ResponseDialog'
import QRScannerModal from './QRScannerModal'
import ConfirmationDialog from '../clock-in-out/ConfirmationDialog'
import { getEmployeeTimeOut } from '../../../services/EmployeeService'

const Transaction = {
    TIME_IN: 1,
    TIME_OUT: 2
}

const QRAttendanceActions = () => {
    const [selectedLocation, setSelectedLocation] = useState('HOME')
    const [currentTime, setCurrentTime] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)
    const [isScanning, setIsScanning] = useState(false)
    const [currentTransaction, setCurrentTransaction] = useState(null)
    const [qrCode, setQRCode] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [overtimeMessage, setOvertimeMessage] = useState('')

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 1000)
        return () => clearInterval(timer)
    }, [])

    const formattedTime = currentTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    })
    const formattedDate = currentTime.toLocaleDateString([], {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

    const handleLocationChange = (event) => setSelectedLocation(event.target.value)

    const handleAttendanceResponse = async (attendance) => {
        try {
            setLoading(true)
            const result = await createQRAttendance(attendance)
            setResponseMessage(result.error || result.message)
        } catch {
            setResponseMessage('An error occurred while recording attendance.')
        } finally {
            setLoading(false)
            setShowResponseDialog(true)
            setIsScanning(false)
        }
    }

    const handleClockAction = (transaction) => {
        setIsScanning(true)
        setCurrentTransaction(transaction)
    }

    const handleConfirmOvertime = (reason) => {
        setIsModalOpen(false)
        handleAttendanceWithTransaction(Transaction.TIME_OUT, qrCode, reason)
    }

    const handleAttendanceWithTransaction = async (transaction, qrcode, reason = '') => {
        const attendance = {
            transaction,
            remarks: selectedLocation === 'HOME' ? 1 : 2,
            qrcode,
            isOvertime: transaction === Transaction.TIME_OUT && reason !== '',
            reason
        }
        await handleAttendanceResponse(attendance)
    }

    const checkOvertime = async (qrData) => {
        try {
            const result = await getEmployeeTimeOut({ qrcode: qrData, isEncrypt: true })
            const timeOut = parseInt(result.timeOut, 10)
            const current = new Date()
            const defaultTimeOut = new Date(current).setHours(timeOut, 0, 0, 0)

            if (current > defaultTimeOut) {
                const overtimeDuration = Math.floor((current - defaultTimeOut) / (1000 * 60))
                // if (overtimeDuration >= 1) {
                const overtimeHours = Math.floor(overtimeDuration / 60)
                const overtimeMinutes = overtimeDuration % 60
                setOvertimeMessage(
                    `Please confirm: Do you have ${overtimeHours} hour/s and ${overtimeMinutes} minutes of overtime? If yes, please enter the reason for the overtime.`
                )
                setIsModalOpen(true)
                // } else {
                //     handleAttendanceWithTransaction(Transaction.TIME_OUT, qrData)
                // }
            } else {
                handleAttendanceWithTransaction(Transaction.TIME_OUT, qrData)
            }
            setResponseMessage(result.error || result.message)
        } catch {
            setResponseMessage('An error occurred while recording attendance.')
        } finally {
            setLoading(false)
            setIsScanning(false)
        }
    }

    const handleAttendanceWithQR = (qrData) => {
        setQRCode(qrData)
        setLoading(true)
        currentTransaction === Transaction.TIME_IN
            ? handleAttendanceWithTransaction(Transaction.TIME_IN, qrData)
            : checkOvertime(qrData)
    }

    return (
        <div className="flex flex-col h-full overflow-auto">
            <div className="flex-1 min-w-[320px] p-4">
                <h3 className="text-2xl font-semibold p-8 text-center">Clock In / Out</h3>

                {/* Timestamp (Date/Time) */}
                <div className="p-16 text-center">
                    <p className="text-md font-semibold text-red-700">{formattedDate}</p>
                    <h3 className="text-6xl font-bold p-4">{formattedTime}</h3>
                </div>

                {/* Location Selection (Radio Buttons) */}
                <div className="p-4 flex space-x-4 items-center justify-center overflow-x-auto">
                    {['HOME', 'OFFICE'].map((location) => (
                        <label
                            key={location}
                            className={`flex items-center text-xl px-4 py-2 rounded ${
                                selectedLocation === location ? 'bg-red-300' : 'bg-transparent'
                            }`}
                        >
                            <input
                                type="radio"
                                value={location}
                                checked={selectedLocation === location}
                                onChange={handleLocationChange}
                                className="mr-2"
                            />
                            {location}
                        </label>
                    ))}
                </div>

                {/* Time In / Time Out Buttons */}
                <div className="p-4 flex space-x-4 justify-center">
                    <button
                        onClick={() => handleClockAction(Transaction.TIME_IN)}
                        className="bg-blue-500 text-white px-6 py-2 w-40 rounded shadow hover:bg-blue-600 text-xl"
                    >
                        TIME IN
                    </button>
                    <button
                        onClick={() => handleClockAction(Transaction.TIME_OUT)}
                        className="bg-red-500 text-white px-6 py-2 w-40 rounded shadow hover:bg-red-600 text-xl"
                    >
                        TIME OUT
                    </button>
                </div>

                <LoadingDialog loading={loading} />
                <ResponseDialog
                    open={showResponseDialog}
                    onClose={() => setShowResponseDialog(false)}
                    responseMessage={responseMessage}
                />
                <ConfirmationDialog
                    isOpen={isModalOpen}
                    message={overtimeMessage}
                    onConfirm={handleConfirmOvertime}
                    onCancel={() => setIsModalOpen(false)}
                />
                {isScanning && <QRScannerModal onCancel={() => setIsScanning(false)} onScan={handleAttendanceWithQR} />}
            </div>
        </div>
    )
}

export default QRAttendanceActions
