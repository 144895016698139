import React, { useEffect, useState } from 'react'
import { createOTAttendance } from '../../../services/AttendanceService'
import LoadingDialog from '../../../helper/LoadingDialog'
import ResponseDialog from '../../../helper/ResponseDialog'

const OTAttendanceActions = () => {
    const [selectedLocation, setSelectedLocation] = useState('HOME')
    const [selectedOT, setSelectedOT] = useState('REGULAR')
    const [currentTime, setCurrentTime] = useState(new Date())
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value)
    }

    const handleOTChange = (event) => {
        setSelectedOT(event.target.value)
    }

    // Update time every second
    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 1000)
        return () => clearInterval(timer)
    }, [])

    // Format time and date
    const formatTime = currentTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: true
    })
    const formatDate = currentTime.toLocaleDateString([], {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })

    const handleClockAction = async (transaction) => {
        setLoading(true)

        const attendance = {
            transaction: transaction, // 1 for Clock In, 2 for Clock Out
            remarks: selectedLocation === 'HOME' ? 1 : 2, // 1 for Home, 2 for Office
            reason: 'test'
        }

        try {
            const result = await createOTAttendance(attendance)

            if (result.error) {
                setResponseMessage(result.error)
            } else {
                setResponseMessage(result.message)
            }
        } catch (error) {
            setResponseMessage('An error occurred while recording attendance.')
        } finally {
            setLoading(false)
            setShowResponseDialog(true)
        }
    }

    return (
        <div className="flex flex-col h-full overflow-auto ">
            <div className="flex-1 min-w-[320px] p-4">
                <h3 className="text-2xl font-semibold">OT Clock In / Out</h3>

                {/* Timestamp (Date/Time) */}
                <div className="p-6 text-center">
                    <p className="text-md font-semibold text-red-700">{formatDate}</p>
                    <h3 className="text-6xl font-bold p-4">{formatTime}</h3>
                </div>

                {/* Location Selection (Radio Buttons) */}
                <div className="p-4 flex space-x-4 items-center justify-center overflow-x-auto">
                    <label
                        className={` text-xl px-4 py-2 rounded ${selectedLocation === 'HOME' ? 'bg-red-300' : 'bg-transparent'}`}
                    >
                        <input
                            type="radio"
                            value="HOME"
                            checked={selectedLocation === 'HOME'}
                            onChange={handleLocationChange}
                            className="mr-2"
                        />
                        HOME
                    </label>
                    <label
                        className={` text-xl px-4 py-2 rounded ${selectedLocation === 'OFFICE' ? 'bg-green-300' : 'bg-transparent'}`}
                    >
                        <input
                            type="radio"
                            value="OFFICE"
                            checked={selectedLocation === 'OFFICE'}
                            onChange={handleLocationChange}
                            className="mr-2"
                        />
                        OFFICE
                    </label>
                </div>

                {/* Location Selection (Radio Buttons) */}
                {/* <label htmlFor="remarks" className="block text-sm font-medium text-gray-700 mb-1">
                  TYPE
                </label> */}
                {/* <div className="p-4 flex space-x-4 items-center justify-center overflow-x-auto">
                    <label
                        className={`flex items-center text-xl px-4 py-2 rounded ${selectedOT === 'REGULAR' ? 'bg-gray-200' : 'bg-transparent'}`}
                    >
                        <input
                            type="radio"
                            value="REGULAR"
                            checked={selectedOT === 'REGULAR'}
                            onChange={handleOTChange}
                            className="mr-2"
                        />
                        REGULAR
                    </label>
                    <label
                        className={`flex items-center text-xl px-4 py-2 rounded ${selectedOT === 'SPECIAL' ? 'bg-gray-200' : 'bg-transparent'}`}
                    >
                        <input
                            type="radio"
                            value="SPECIAL"
                            checked={selectedOT === 'SPECIAL'}
                            onChange={handleOTChange}
                            className="mr-2"
                        />
                        SPECIAL
                    </label>
                </div> */}

                {/* Textarea */}
                <div className="mb-4">
                    <label htmlFor="remarks" className="block text-sm font-medium text-gray-700 mb-1">
                        REASON
                    </label>
                    <textarea
                        id="reason"
                        rows="4"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 p-4"
                        placeholder="Explain your reason for working overtime."
                    ></textarea>
                </div>

                {/* Approval By Dropdown */}
                {/* <div className="mb-4">
                    <label htmlFor="approval-by" className="block text-sm font-medium text-gray-700 mb-1">
                        APPROVAL BY
                    </label>
                    <select
                        id="approval-by"
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 p-4"
                    >
                        <option value="manager">Manager</option>
                        <option value="supervisor">Supervisor</option>
                        <option value="hr">HR</option>
                        <option value="admin">Admin</option>
                    </select>
                </div> */}

                {/* Time In / Time Out Buttons */}
                <div className="p-4 flex space-x-4 justify-center">
                    <button
                        onClick={() => handleClockAction(1)}
                        className="bg-blue-500 text-white px-6 py-2 w-40 rounded shadow hover:bg-blue-600 text-xl"
                    >
                        TIME IN
                    </button>
                    <button
                        onClick={() => handleClockAction(2)}
                        className="bg-red-500 text-white px-6 py-2 w-40 rounded shadow hover:bg-red-600 text-xl"
                    >
                        TIME OUT
                    </button>
                </div>

                {/* Loading Dialog */}
                <LoadingDialog loading={loading} />

                {/* Response Dialog */}
                <ResponseDialog
                    open={showResponseDialog}
                    onClose={() => setShowResponseDialog(false)}
                    responseMessage={responseMessage}
                />
            </div>
        </div>
    )
}

export default OTAttendanceActions
