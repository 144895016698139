import React, { useContext, useEffect, useState } from 'react'
import { DASHBOARD_SIDEBAR_LINKS } from '../../lib/consts/navigation' // Ensure this contains categories and sub-items
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import ASCLOGO from '../../assets/asc-logo.jpg'
import { UserContext } from '../../helper/routes/UserContext'

const linkClass =
    'flex items-center gap-2 font-light px-3 py-2 hover:bg-red-100 hover:no-underline active:bg-red-400 rounded-sm text-base'

// Sidebar component
export default function Sidebar() {
    const { userRole } = useContext(UserContext) // Get user role from context
    const { pathname } = useLocation()
    const [filteredLinks, setFilteredLinks] = useState([])

    useEffect(() => {
        // Filter links based on user role whenever userRole changes
        const links = DASHBOARD_SIDEBAR_LINKS.filter((category) => category.roles.includes(userRole))
        setFilteredLinks(links)
    }, [userRole])

    return (
        <div className="bg-white w-60 p-3 flex flex-col text-red-600">
            <div
                className="h-20 w-full bg-cover bg-no-repeat flex justify-center items-center"
                style={{
                    backgroundImage: `url(${ASCLOGO})`,
                    backgroundSize: 'contain' // Use 'contain' to ensure the full image is displayed
                }}
            ></div>

            {/* Main links */}
            <div className="flex-1 py-8 flex flex-col gap-0.5">
                {filteredLinks.map((category) => (
                    <div key={category.key} className="category">
                        <h4 className="text-sm text-red-700 mb-2">{category.label}</h4>
                        {/* Render sub-items under each category */}
                        <ul>
                            {category.links.map((item) => (
                                <SidebarLink key={item.key} item={item} />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            {/* Footer */}
            <div className="flex flex-col gap-0.5 pt-2 border-t border-white-700">
                <div className="text-xs">Attendance Management System @ 2024-01</div>
            </div>
        </div>
    )
}

// SidebarLink component
function SidebarLink({ item }) {
    const { pathname } = useLocation()

    return (
        <li>
            <Link
                className={classNames(
                    pathname === item.path ? 'text-red-950 font-bold' : 'text-red-600 font-bold',
                    linkClass
                )}
                to={item.path}
            >
                <span className="text-base">{item.icon}</span>
                <span className="font-bold text-xs">{item.label}</span>
            </Link>
        </li>
    )
}
