import React, { Fragment } from "react"
import { Popover, PopoverButton, PopoverPanel,Transition,Menu, MenuItems ,MenuButton,MenuItem} from '@headlessui/react'
import { HiOutlineBell, HiOutlineSearch, HiOutlineChatAlt } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import profileImage from '../../assets/profile.png';

export default function Header(){
    
	const navigate = useNavigate()


	const handleLogout = () => {
		localStorage.removeItem('authToken');
		localStorage.removeItem('userRole');
		navigate('/login');
	  };


    return(
        <div className="bg-white h-20 px-4 flex items-center border-b border-gray-200 justify-between">
           <div className="text-red-600 font-bold font-xl">
                Attendance Management System
           </div>

           <div className="flex items-center gap-2 mr-2">
                <Popover className="relative">
					{({ open }) => (
						<>
							<PopoverButton
								className={classNames(
									open && 'bg-gray-100',
									'group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100'
								)}
							>
							<HiOutlineChatAlt fontSize={24} />
							</PopoverButton>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<PopoverPanel className="absolute right-0 z-10 mt-2.5 transform w-80">
									<div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
										<strong className="text-gray-700 font-medium">Messages</strong>
										<div className="mt-2 py-1 text-sm">This is messages panel.</div>
									</div>
								</PopoverPanel>
							</Transition>
						</>
					)}
				</Popover>
                <Popover className="relative">
					{({ open }) => (
						<>
							<PopoverButton
								className={classNames(
									open && 'bg-gray-100',
									'group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100'
								)}
							>
								<HiOutlineBell fontSize={24} />
							</PopoverButton>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<PopoverPanel className="absolute right-0 z-10 mt-2.5 transform w-80">
									<div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
										<strong className="text-gray-700 font-medium">Notifications</strong>
										<div className="mt-2 py-1 text-sm">This is notification panel.</div>
									</div>
								</PopoverPanel>
							</Transition>
						</>
					)}
				</Popover>
                <Menu as="div" className="relative">
					<div>
						<MenuButton className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
							<span className="sr-only">Open user menu</span>
							<div
								className="h-10 w-10 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
								style={ { backgroundImage: `url(${profileImage})` }}
                                
							>
							<span className="sr-only">Marc Backes</span>
							</div>
						</MenuButton>
					</div>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<MenuItems className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
							<MenuItem>
								{({ active }) => (
									<div
										onClick={() => navigate('/profile')}
										className={classNames(
											active && 'bg-gray-100',
											'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
										)}
									>
										Your Profile
									</div>
								)}
							</MenuItem>
							
							<MenuItem>
								{({ close }) => (
									<div
										className="bg-gray-100 hover:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200"
										onClick={() => {
										handleLogout();
										close();
										}}
										onMouseEnter={(e) => e.currentTarget.classList.add('bg-gray-200')}
										onMouseLeave={(e) => e.currentTarget.classList.remove('bg-gray-200')}
								    >
										Sign out
								  	</div>
								)}
							</MenuItem>
						</MenuItems>
					</Transition>
				</Menu>

           </div>
            
        </div>
    )
}


