import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Button, Paper, Stack } from '@mui/material'
import { fetchEmployees } from '../../services/EmployeeService'
import EmployeeRegisterModal from './EmployeeRegisterModal'
import { useNavigate } from 'react-router-dom'
import { HiEye, HiPencilAlt, HiPlus, HiTrash } from 'react-icons/hi'

const Status = {
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED'
}

const EmployeeList = () => {
    const navigate = useNavigate() // Initialize navigate
    const DEFAULT_PAGE_SIZE = 10
    const [dtr, setDTR] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectionModel, setSelectionModel] = useState([])
    const [recordType, setRecordType] = useState(Status.PENDING)
    const [searchTerm, setSearchTerm] = useState(null)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [totalCount, setTotalCount] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [registerDialogOpen, setRegisterDialogOpen] = useState(false)

    const columns = [
        { field: 'employeeId', headerName: 'Employee Id', width: 150 },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'position', headerName: 'Position', width: 150 },
        { field: 'contactNumber', headerName: 'Contact No', width: 150 },
        { field: 'dateHired', headerName: 'Date Hired', width: 150 },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params.value
                let bgColor = ''
                let textColor = ''

                switch (status) {
                    case Status.ACTIVE:
                        bgColor = 'bg-green-100'
                        textColor = 'text-green-700'
                        break
                    case Status.DEACTIVATED:
                        bgColor = 'bg-red-100'
                        textColor = 'text-red-700'
                        break
                }

                return <span className={`px-3 py-1 rounded-full font-semibold ${bgColor} ${textColor}`}>{status}</span>
            }
        }
    ]

    useEffect(() => {
        fetchEmployeeList(recordType, searchTerm, page, pageSize)
    }, [recordType, searchTerm, page, pageSize, refresh])

    const fetchEmployeeList = async (recordType, search, page, limit) => {
        setLoading(true)
        try {
            const filter = {
                page,
                limit,
                search: search
            }
            let response = await fetchEmployees(filter)
            const dailyRecords = response.data || []
            setTotalCount(response.count || 0)
            const formattedData = dailyRecords.map((record) => ({
                id: record.id,
                employeeId: record.employeeId,
                firstName: record.firstName,
                lastName: record.lastName,
                position: record.position,
                dateHired: record.dateHired,
                contactNumber: record.contactNumber,
                status: record.status
            }))
            setDTR(formattedData)
        } catch (error) {
            console.error('Error fetching DTR data:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value)
    }

    // Handle opening and closing of register modal
    const handleOpenRegisterDialog = () => {
        setRegisterDialogOpen(true)
    }

    const handleCloseRegisterDialog = () => {
        setRegisterDialogOpen(false)
    }

    const handleRowClick = (params) => {
        const employeeId = `${params.row.dateHired}-${params.row.employeeId}-${params.row.id}`
        navigate(`/employee/info/${employeeId}`) // Navigate to the employee info page with ID
    }

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="flex items-center justify-between w-full mb-4">
                <h1 className="text-xl font-bold text-black">Employee</h1>
            </div>

            {loading && <p>Loading...</p>}
            {responseMessage && <p className="text-green-500">{responseMessage}</p>}

            {/* Search and Register Button */}
            <div className="flex space-x-4 mb-4">
                <input
                    type="text"
                    placeholder="Search..."
                    className="p-2 border rounded w-full"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />

                <button
                    onClick={handleOpenRegisterDialog} // Open register modal
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    Register
                </button>
            </div>

            {/* Employee DataGrid */}
            <Paper sx={{ width: '100%', height: 'calc(100vh - 250px)', minWidth: 300 }}>
                <DataGrid
                    rows={dtr}
                    columns={columns.map((column) => ({ ...column, flex: 1 }))}
                    loading={loading}
                    page={page - 1}
                    pageSize={pageSize}
                    rowCount={totalCount}
                    paginationMode="server"
                    initialState={{
                        ...dtr.initialState,
                        pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE } }
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    onPaginationModelChange={(newPaginationModel) => {
                        setPage(newPaginationModel.page + 1)
                        setPageSize(newPaginationModel.pageSize)
                    }}
                    onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                    selectionModel={selectionModel}
                    onRowClick={handleRowClick}
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-columnHeaders': {
                            fontWeight: 'bold'
                        },
                        '& .MuiDataGrid-cell': {
                            cursor: 'default'
                        }
                    }}
                />
            </Paper>

            {/* Employee Register Modal */}
            <EmployeeRegisterModal
                open={registerDialogOpen}
                onClose={handleCloseRegisterDialog}
                onRegisterSuccess={() => setRefresh((prev) => !prev)}
            />
        </div>
    )
}

export default EmployeeList
