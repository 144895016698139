import axios from 'axios'
import apiClient from '../clients/ApiClient' // Update this import path as needed

/**
 * Function to handle the attendance API request.
 * @param {object} attendance - Object containing employeeId and transactionType and remarks.
 * @param {string} employeeId - Employee Id.
 * @param {int} transactionType - Indetifier 1 = TIME IN | 2 = TIME OUT.
 * @param {int} remarks - Indetifier 1 = HOME | 2 = OFFICE.
 * @returns {Promise<object>} - The response data from the API.
 * @throws {Error} - Throws an error if the login fails.
 */
export const fetchEmployees = async (filter) => {
    try {
        const response = await apiClient.get('/employees/', { params: filter })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const createEmployee = async (employeeDetails) => {
    try {
        const response = await apiClient.post('/employees/create', employeeDetails)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const fetchEmployeeInfo = async (param) => {
    try {
        const temporaryId = param // assuming param is defined
        const id = temporaryId.split('-').pop()
        console.log('temporaryId', id) // Output: "1"

        const response = await apiClient.get(`/employees/${id}`) // Use backticks here
        console.log('response', response)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const getActiveEmployees = async (filter) => {
    try {
        const response = await apiClient.get('/employees/active', { params: filter })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const getEmployeeTimeOut = async (employeeDetails) => {
    try {
        console.log(employeeDetails)
        const response = await apiClient.post('/employees/public/timeout', employeeDetails)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const getEmployeeTimeOutWithToken = async () => {
    try {
        const response = await apiClient.get('/employees/timeout')
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const updateEmployeeWorkingTime = async (employeeDetails) => {
    try {
        const response = await apiClient.put('/employees/update/working-schedule', employeeDetails)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}
