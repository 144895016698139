import React, { useState } from 'react'

const LeaveLayout = () => {
    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="flex items-center justify-between w-full mb-4">
                <h1 className="text-xl font-bold text-black">Leave</h1>
            </div>
        </div>
    )
}

export default LeaveLayout
