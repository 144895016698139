// apiClient.ts
import axios from 'axios'

const apiClient = axios.create({
    baseURL: 'http://localhost:5789',
    timeout: 10000, // Set a reasonable timeout
    headers: {
        'Content-Type': 'application/json'
    }
})

// Add a request interceptor to dynamically include the latest token
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken') // Get the token before each request
        if (token) {
            config.headers.Authorization = `Bearer ${token}` // Set the token in headers
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default apiClient
