import axios from 'axios'
import apiClient from '../clients/ApiClient'

/**
 * Function to handle the attendance API request.
 * @param {object} attendance - Object containing employeeId and transactionType and remarks.
 * @returns {Promise<object>} - The response data from the API.
 * @throws {Error} - Throws an error if the login fails.
 */
export const fetchAllAttendance = async (attendance) => {
    try {
        const response = await apiClient.get('/dtr/generate', {
            params: attendance,
            responseType: 'blob' // important to get the file as binary (blob)
        })

        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const fetchOvertimeAttendance = async (attendance) => {
    try {
        const response = await apiClient.get('/overtime/generate', {
            params: attendance,
            responseType: 'blob' // important to get the file as binary (blob)
        })

        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}
