import React from 'react';
import { Dialog } from '@headlessui/react';
import { FaSpinner } from 'react-icons/fa';

const LoadingDialog = ({ loading }) => {
  return (
    <Dialog open={loading} onClose={() => {}}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-6 rounded shadow-lg flex flex-col items-center justify-center">
          <FaSpinner className="animate-spin text-red-500 text-3xl mb-4" />
          <p className="text-lg">Loading...</p>
        </div>
      </div>
    </Dialog>
  );
};

export default LoadingDialog;
