import React, { useState } from 'react'
import { fetchAllAttendance, fetchOvertimeAttendance } from '../../services/ReportService'

const ReportComponent = () => {
    const [dtr, setDTR] = useState([]) // State to store employee data
    const [loading, setLoading] = useState(false) // Loading state
    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd] = useState('')
    const [recordType, setRecordType] = useState('regular') // State for record type

    const fetchDtrData = async (event) => {
        event.preventDefault() // Prevent default form submission

        // Validate the date range
        if (new Date(dateStart) > new Date(dateEnd)) {
            alert('The start date cannot be after the end date.')
            return
        }

        setLoading(true) // Set loading to true while fetching data

        try {
            const attendance = {
                dateStart: dateStart,
                dateEnd: dateEnd
            }
            let response = null

            if (recordType === 'regular') {
                response = await fetchAllAttendance(attendance)
            } else if (recordType === 'overtime') {
                response = await fetchOvertimeAttendance(attendance)
            }

            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `employee_dtr_${recordType}_${dateStart}_${dateEnd}.xlsx`) // Set the file name
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.error('Error fetching DTR data:', error)
            alert('Failed to generate report. Please try again.')
        } finally {
            setLoading(false) // Set loading to false after the API call
        }
    }

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="flex items-center justify-between w-full mb-4">
                <h1 className="text-xl font-bold text-black">Generate Daily Time Records</h1>
            </div>

            {/* Date Range Selectors - Responsive */}
            <div className="flex items-center space-x-4 p-16 bg-white">
                <form id="dtrForm" onSubmit={fetchDtrData}>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Record Type</label>
                        <select
                            id="recordType"
                            value={recordType}
                            onChange={(e) => setRecordType(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-6 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                            <option value="regular">Regular</option>
                            <option value="overtime">Overtime</option>
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Date Start</label>
                        <input
                            type="date"
                            id="dateStart"
                            value={dateStart}
                            required
                            onChange={(e) => setDateStart(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Date End</label>
                        <input
                            type="date"
                            id="dateEnd"
                            value={dateEnd}
                            required
                            onChange={(e) => setDateEnd(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div className="mt-6">
                        <button
                            type="submit"
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-60"
                        >
                            {loading ? 'Generating...' : 'Generate'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ReportComponent
