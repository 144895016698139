import axios from 'axios'
import apiClient from '../clients/ApiClient' // Update this import path as needed

/**
 * Function to handle the attendance API request.
 * @param {object} attendance - Object containing employeeId and transactionType and remarks.
 * @param {string} employeeId - Employee Id.
 * @param {int} transactionType - Indetifier 1 = TIME IN | 2 = TIME OUT.
 * @param {int} remarks - Indetifier 1 = HOME | 2 = OFFICE.
 * @returns {Promise<object>} - The response data from the API.
 * @throws {Error} - Throws an error if the login fails.
 */
export const createAttendance = async (attendance) => {
    try {
        const response = await apiClient.post('/dtr/create', attendance)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

/**
 * Function to handle the attendance API request.
 * @param {object} attendance - Object containing employeeId and transactionType and remarks.
 * @param {string} employeeId - Employee Id.
 * @param {int} transactionType - Indetifier 1 = TIME IN | 2 = TIME OUT.
 * @param {int} remarks - Indetifier 1 = HOME | 2 = OFFICE.
 * @param {string} qrcode
 * @returns {Promise<object>} - The response data from the API.
 * @throws {Error} - Throws an error if the login fails.
 */
export const createQRAttendance = async (attendance) => {
    try {
        const response = await apiClient.post('/dtr/attendance/qr', attendance)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const fetchAttendance = async (attendance) => {
    try {
        const response = await apiClient.get('/dtr/user/', { params: attendance })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

/**
 * Function to handle the attendance API request.
 * @param {object} attendance - Object containing employeeId and transactionType and remarks.
 * @param {string} employeeId - Employee Id.
 * @param {int} transactionType - Indetifier 1 = TIME IN | 2 = TIME OUT.
 * @param {int} remarks - Indetifier 1 = HOME | 2 = OFFICE.
 * @returns {Promise<object>} - The response data from the API.
 * @throws {Error} - Throws an error if the login fails.
 */
export const createOTAttendance = async (attendance) => {
    try {
        const response = await apiClient.post('/overtime/create', attendance)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const fetchOvertimeAttendance = async (attendance) => {
    try {
        const response = await apiClient.get('/overtime/user/', { params: attendance })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const fetchOvertime = async (attendance) => {
    try {
        const response = await apiClient.get('/overtime/list/', { params: attendance })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const updateOvertime = async (overtimeStatus) => {
    try {
        const response = await apiClient.put('/overtime/update/status/', overtimeStatus)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}
