import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { fetchOvertime, updateOvertime } from '../../services/AttendanceService'
import { Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const Status = {
    PENDING: 1,
    APPROVED: 2,
    DISAPPROVED: 3
}

const OvertimeRequestList = () => {
    const DEFAULT_PAGE_SIZE = 10
    const [dtr, setDTR] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectionModel, setSelectionModel] = useState([])
    const [recordType, setRecordType] = useState(Status.PENDING)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
    const [totalCount, setTotalCount] = useState(0)

    // State for confirmation dialog and response messages
    const [responseMessage, setResponseMessage] = useState('')
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
    const [confirmAction, setConfirmAction] = useState(null) // Store the action (approve/disapprove)

    const columns = [
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'date', headerName: 'Date', width: 150 },
        { field: 'timeIn', headerName: 'Time In', width: 150 },
        { field: 'timeOut', headerName: 'Time Out', width: 150 },
        {
            field: 'remarks',
            headerName: 'Working At',
            width: 150,
            renderCell: (params) => {
                const remark = params.value
                const bgColor = remark === 'Home' ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'
                return <span className={`px-3 py-1 rounded-full font-semibold ${bgColor}`}>{remark}</span>
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params.value

                let bgColor = ''
                let textColor = ''

                switch (status) {
                    case 'Approved':
                        bgColor = 'bg-green-100'
                        textColor = 'text-green-700'
                        break
                    case 'Pending':
                        bgColor = 'bg-yellow-100'
                        textColor = 'text-yellow-700'
                        break
                    case 'Disapproved':
                        bgColor = 'bg-red-100'
                        textColor = 'text-red-700'
                        break
                }

                return <span className={`px-3 py-1 rounded-full font-semibold ${bgColor} ${textColor}`}>{status}</span>
            }
        },
        { field: 'reason', headerName: 'Reason', width: 300 }
    ]

    useEffect(() => {
        fetchDtrData(recordType, page, pageSize)
    }, [recordType, page, pageSize])

    const fetchDtrData = async (recordType, page, limit) => {
        setLoading(true)
        try {
            const attendance = {
                status: parseInt(recordType),
                page,
                limit
            }
            let response = await fetchOvertime(attendance)
            const dailyRecords = response.data || []
            setTotalCount(response.count || 0)
            const formattedData = dailyRecords.map((record) => ({
                id: record.ot_dtr_id,
                firstName: record.firstName,
                lastName: record.lastName,
                date: record.ot_dtr_date ? new Date(record.ot_dtr_date).toLocaleDateString() : '-',
                timeIn: record.ot_dtr_in ? new Date(record.ot_dtr_in).toLocaleTimeString() : '-',
                timeOut: record.ot_dtr_out ? new Date(record.ot_dtr_out).toLocaleTimeString() : '-',
                remarks: record.ot_dtr_remarks || '-',
                status: record.ot_dtr_status || '-',
                reason: record.ot_dtr_reason || '-'
            }))
            setDTR(formattedData)
        } catch (error) {
            console.error('Error fetching DTR data:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleOpenConfirmDialog = (action) => {
        if (selectionModel.length === 0) {
            setResponseMessage('Please select at least one record.')
            return
        }
        setConfirmAction(action)
        setConfirmDialogOpen(true)
    }

    const handleCloseConfirmDialog = () => {
        setConfirmDialogOpen(false)
    }

    const handleConfirmAction = async () => {
        setConfirmDialogOpen(false)
        setLoading(true)

        try {
            const request = {
                ids: selectionModel,
                status: confirmAction
            }

            const response = await updateOvertime(request)
            setResponseMessage(response.message)

            fetchDtrData(recordType, page, pageSize)
        } catch (error) {
            console.error('Error updating status:', error)
            setResponseMessage('Failed to update status.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="flex items-center justify-between w-full mb-4">
                <h1 className="text-xl font-bold text-black">Overtime Request Status</h1>
            </div>

            {/* Display response messages */}
            {loading && <p>Loading...</p>}
            {responseMessage && <p className="text-green-500">{responseMessage}</p>}

            {/* Buttons for Approve and Disapprove */}
            <div className="flex space-x-4 mb-4">
                <div>
                    <label className="px-4 py-2" htmlFor="recordType">
                        Filter by Status:
                    </label>
                    <select
                        id="recordType"
                        className="p-2 border rounded"
                        value={recordType}
                        onChange={(e) => setRecordType(e.target.value)}
                    >
                        <option value="1">Pending</option>
                        <option value="2">Approved</option>
                        <option value="3">Disapproved</option>
                    </select>
                </div>

                <button
                    onClick={() => {
                        handleOpenConfirmDialog(Status.APPROVED)
                    }}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                    Approve
                </button>
                <button
                    onClick={() => {
                        handleOpenConfirmDialog(Status.DISAPPROVED)
                    }}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    Disapprove
                </button>
            </div>

            <Paper sx={{ height: '100%', width: '100%' }}>
                <DataGrid
                    rows={dtr}
                    columns={columns}
                    loading={loading}
                    page={page - 1}
                    pageSize={pageSize}
                    rowCount={totalCount}
                    paginationMode="server"
                    initialState={{
                        ...dtr.initialState,
                        pagination: { paginationModel: { pageSize: DEFAULT_PAGE_SIZE } }
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    onPaginationModelChange={(newPaginationModel) => {
                        setPage(newPaginationModel.page + 1)
                        setPageSize(newPaginationModel.pageSize)
                    }}
                    checkboxSelection
                    onRowSelectionModelChange={(newSelectionModel) => setSelectionModel(newSelectionModel)}
                    selectionModel={selectionModel}
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-columnHeaders': {
                            fontWeight: 'bold'
                        },
                        '& .MuiDataGrid-cell': {
                            cursor: 'default'
                        }
                    }}
                />
            </Paper>

            {/* Confirmation Dialog */}
            <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to {confirmAction === Status.APPROVED ? 'approve' : 'disapprove'} the
                        selected requests?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseConfirmDialog}
                        sx={{ color: 'gray', borderColor: 'gray' }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirmAction}
                        sx={{ backgroundColor: 'green', color: 'white', '&:hover': { backgroundColor: 'darkgreen' } }}
                        variant="contained"
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default OvertimeRequestList
