import React, { useState } from 'react'

const ConfirmationDialog = ({ isOpen, message, onConfirm, onCancel }) => {
    const [overtimeReason, setOvertimeReason] = useState('')

    if (!isOpen) return null

    const handleConfirm = () => {
        onConfirm(overtimeReason) // Pass the reason back to the parent
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                <p className="text-lg mb-4">{message}</p>

                <label className="block mb-2 text-sm font-medium text-gray-700">Reason for Overtime</label>
                <input
                    type="text"
                    value={overtimeReason}
                    onChange={(e) => setOvertimeReason(e.target.value)}
                    placeholder="Enter reason"
                    className="w-full p-2 border border-gray-300 rounded"
                />

                <div className="flex justify-end space-x-4 mt-4">
                    <button onClick={onCancel} className="bg-gray-500 text-white px-4 py-2 rounded">
                        No
                    </button>
                    <button
                        onClick={handleConfirm}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                        disabled={!overtimeReason} // Disable if no reason is provided
                    >
                        Yes
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ConfirmationDialog
