import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from './UserContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
    const token = localStorage.getItem('authToken');
    const { userRole } = useContext(UserContext);
    const storedRole = localStorage.getItem('userRole');

    if (!token) {
        return <Navigate to="/login" />; 
    }

   
    const currentRole = userRole || storedRole;
    
    console.log(userRole)
    if (!currentRole || !allowedRoles.includes(currentRole)) {
        return <Navigate to="/unauthorized" />;
    }

    return children; 
};

export default ProtectedRoute;
