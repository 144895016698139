import {
    HiOutlineQuestionMarkCircle,
    HiOutlineFolderDownload,
    HiOutlineClipboardList,
    HiOutlineCog,
    HiOutlineClock
} from 'react-icons/hi'

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'attendance',
        label: 'Attendance',
        roles: ['super admin', 'employee', 'finance', 'management'], // Roles that can see this category
        links: [
            {
                key: 'clockInOut',
                label: 'Clock In/Out',
                path: '/',
                icon: <HiOutlineClock />,
                roles: ['super admin', 'user']
            },
            {
                key: 'ot',
                label: 'OT',
                path: '/attendance/ot',
                icon: <HiOutlineClock />,
                roles: ['super admin', 'user']
            },
            {
                key: 'applyLeave',
                label: 'Leave',
                path: '/attendance/leave',
                icon: <HiOutlineClock />,
                roles: ['super admin', 'user']
            },
            {
                key: 'dtr',
                label: 'DTR',
                path: '/attendance/dtr',
                icon: <HiOutlineClock />,
                roles: ['super admin', 'user']
            }
        ]
    },
    {
        key: 'finance',
        label: 'Finance',
        roles: ['finance'],
        links: [
            {
                key: 'generateDTR',
                label: 'Generate DTR',
                path: '/finance/generate-dtr',
                icon: <HiOutlineFolderDownload />,
                roles: ['super admin']
            },
            {
                key: 'employees',
                label: 'Employee Records',
                path: '/employee',
                icon: <HiOutlineFolderDownload />,
                roles: ['super admin']
            }
        ]
    },
    {
        key: 'management',
        label: 'Management',
        roles: ['management'],
        links: [
            {
                key: 'generateDTR',
                label: 'Overtime Approval',
                path: '/management/overtime-approval',
                icon: <HiOutlineClipboardList />,
                roles: ['management']
            }
            // {
            //     key: 'leaveApproval',
            //     label: 'Leave Approval',
            //     path: '/management/leave-approval',
            //     icon: <HiOutlineClipboardList />,
            //     roles: ['management']
            // }
        ]
    },
    {
        key: 'superadmin',
        label: 'Super Admin',
        roles: ['super admin'],
        links: [
            {
                key: 'roles',
                label: 'Roles',
                path: '/roles',
                icon: <HiOutlineFolderDownload />,
                roles: ['super admin']
            },
            {
                key: 'employees',
                label: 'Employee Records',
                path: '/employee',
                icon: <HiOutlineFolderDownload />,
                roles: ['super admin']
            },
            {
                key: 'employees',
                label: 'Employee Accounts',
                path: '/user-account',
                icon: <HiOutlineFolderDownload />,
                roles: ['super admin']
            },
            {
                key: 'positions',
                label: 'Position',
                path: '/positions',
                icon: <HiOutlineFolderDownload />,
                roles: ['super admin']
            }
        ]
    }
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'settings',
        label: 'Settings',
        path: '/settings',
        icon: <HiOutlineCog />
    },
    {
        key: 'support',
        label: 'Help & Support',
        path: '/support',
        icon: <HiOutlineQuestionMarkCircle />
    }
]
