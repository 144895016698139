import React from 'react';
import { Dialog, DialogTitle } from '@headlessui/react';

const ResponseDialog = ({ open, onClose, responseMessage }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
        <div className="bg-white p-6 rounded shadow-lg">
          <DialogTitle className="text-xl font-semibold">Response</DialogTitle>
          <div className="mt-2 text-lg">
            {responseMessage}
          </div>
          <div className="mt-4 flex justify-end">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ResponseDialog;
