import React, { useState, useEffect } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Stack,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormHelperText
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { fetchUserAccountInfo, updateStatus } from '../../services/UserAccountService'
import { getActiveUserRole } from '../../services/UserRoleService'

const UserAccountInfo = () => {
    const { username } = useParams()
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [employee, setEmployeeData] = useState({
        userRoleId: '',
        username: '',
        employee: {
            firstName: '',
            lastName: ''
        },
        userRole: { id: '', role: '' },
        archivedAt: null
    })

    const [roles, setRoles] = useState([])
    const [errors, setErrors] = useState({})

    useEffect(() => {
        const fetchEmployeeData = async () => {
            const data = await fetchUserAccountInfo(username)
            setEmployeeData({
                ...data,
                userRoleId: data.userRole?.id || ''
            })
        }
        fetchEmployeeData()
    }, [username])

    const handleChange = (event) => {
        const { name, value } = event.target
        setEmployeeData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    useEffect(() => {
        const loadRoles = async () => {
            try {
                const result = await getActiveUserRole()
                setRoles(result || [])
            } catch (error) {
                console.error('Error fetching roles:', error)
            }
        }
        loadRoles()
    }, [])

    const handleFormSubmit = async (event) => {
        event.preventDefault()
        console.log('employee', employee)
    }

    const handleUpdate = async () => {
        console.log('Updating employee:', employee)
    }

    const handleDeactivate = async (event) => {
        event.preventDefault()
        console.log('Deactivating employee:', employee)
    }

    const handleConfirmAction = async () => {
        // Perform deactivate or activate based on archivedAt status
        console.log(employee.archivedAt ? 'Activating user' : 'Deactivating user')

        const request = {
            id: employee.id,
            status: employee.archivedAt ? 1 : 0
        }
        try {
            const result = await updateStatus(request)

            if (result.error) {
                setResponseMessage(result.error)
            } else {
                setResponseMessage(result.message)
                // onRegisterSuccess()
            }
            setShowResponseDialog(true)
        } catch (error) {
            setResponseMessage('An error occurred while recording attendance.')
            setShowResponseDialog(true)
        } finally {
            setLoading(false)
            // onClose()
        }
        setShowConfirmDialog(false)
        // Here, add actual logic to activate or deactivate the user
    }
    const handleCancelAction = () => {
        setShowConfirmDialog(false)
    }
    const handleDeactivateClick = () => {
        setShowConfirmDialog(true)
    }
    return (
        <div className="bg-white shadow-md rounded-lg p-6 max-w-2xl mx-auto">
            <h2 className="text-2xl font-semibold mb-4 text-center">User Account Details</h2>
            <form>
                <Stack spacing={3}>
                    <TextField
                        label="Full Name"
                        value={`${employee.employee.firstName} ${employee.employee.lastName}`}
                        fullWidth
                    />

                    <TextField name="username" label="Username" value={employee.username} fullWidth />

                    <FormControl fullWidth margin="normal" error={Boolean(errors.userRoleId)}>
                        <InputLabel>Role</InputLabel>
                        <Select
                            name="userRoleId"
                            value={employee.userRoleId || ''}
                            onChange={handleChange}
                            label="Role"
                        >
                            {roles.length > 0 ? (
                                roles.map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.role}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem disabled>No roles available</MenuItem>
                            )}
                        </Select>
                        {errors.userRoleId && <FormHelperText>{errors.userRoleId}</FormHelperText>}
                    </FormControl>

                    <Button type="button" onClick={handleUpdate} color="secondary" variant="contained">
                        Update
                    </Button>

                    <Button type="button" onClick={handleDeactivateClick} color="primary" variant="contained">
                        {employee.archivedAt ? 'Activate' : 'Deactivate'}
                    </Button>
                </Stack>
            </form>
            {/* Confirmation Dialog */}
            <Dialog open={showConfirmDialog} onClose={handleCancelAction}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <p>
                        {employee.archivedAt
                            ? 'Are you sure you want to activate this user?'
                            : 'Are you sure you want to deactivate this user?'}
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelAction} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmAction} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Response Dialog */}
            <Dialog open={showResponseDialog} onClose={() => setShowResponseDialog(false)}>
                <DialogTitle>Response</DialogTitle>
                <DialogContent>
                    <p>{responseMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResponseDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UserAccountInfo
