import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getActiveUserRole } from '../../services/UserRoleService'

import { createPosition } from '../../services/PositionService'

const PositionRegisterModal = ({ open, onClose, onRegisterSuccess }) => {
    const [errors, setErrors] = useState({})
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [newEmployee, setNewEmployee] = useState({})

    const validateFields = () => {
        const newErrors = {}
        const requiredFields = ['position', 'timeIn', 'timeOut']

        requiredFields.forEach((field) => {
            if (!newEmployee[field]) {
                newErrors[field] = 'This field is required'
            }
        })

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleRegisterInputChange = (e) => {
        const { name, value } = e.target

        setNewEmployee((prev) => {
            const updatedEmployee = { ...prev, [name]: value.toUpperCase() }

            return updatedEmployee
        })
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault()

        console.log('position', newEmployee)

        if (validateFields()) {
            try {
                const result = await createPosition(newEmployee)

                if (result.error) {
                    setResponseMessage(result.error)
                } else {
                    setResponseMessage(result.message)
                    onRegisterSuccess()
                }
                setShowResponseDialog(true)
            } catch (error) {
                setResponseMessage('An error occurred while recording attendance.')
                setShowResponseDialog(true)
            } finally {
                setLoading(false)
                onClose()
            }
        }
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Add Position</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Stack spacing={3} style={{ marginTop: '16px' }}>
                            {/* Form fields */}

                            <TextField
                                margin="normal"
                                name="position"
                                label="Position"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.position}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.position)}
                                helperText={errors.position}
                            />

                            <TextField
                                margin="normal"
                                name="timeIn"
                                label="Time In"
                                fullWidth
                                variant="outlined"
                                type="time" // This is the key to making it a time input
                                value={newEmployee.timeIn}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.timeIn)}
                                helperText={errors.timeIn}
                            />

                            <TextField
                                margin="normal"
                                name="timeOut"
                                label="Time Out"
                                fullWidth
                                variant="outlined"
                                type="time" // This is the key to making it a time input
                                value={newEmployee.timeOut}
                                onChange={handleRegisterInputChange}
                                error={Boolean(errors.timeOut)}
                                helperText={errors.timeOut}
                            />
                        </Stack>

                        <DialogActions style={{ marginTop: '16px' }}>
                            <Button onClick={onClose} color="secondary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                Register
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Response Dialog */}
            <Dialog open={showResponseDialog} onClose={() => setShowResponseDialog(false)}>
                <DialogTitle>Response</DialogTitle>
                <DialogContent>
                    <p>{responseMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResponseDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default PositionRegisterModal
