import { startRegistration, startAuthentication } from '@simplewebauthn/browser'
import apiClient from '../clients/ApiClient'

const rpOrigin = 'https://your-app.com'

// // Register
// export const registerTest = async (userID, username) => {
//     const body = {
//         userID: userID,
//         username: username
//     }
//     console.log('body', body)

//     try {
//         // Request registration options from the backend
//         const response = await apiClient.post('/webauthn/register/options', body)
//         const options = response.data
//         console.log('options', options)

//         // Convert challenge and user ID to Uint8Array
//         options.challenge = convertToUint8Array(options.challenge)
//         options.user.id = convertToUint8Array(options.user.id)
//         // console.log('Challenge (before decoding):', options.challenge)
//         // console.log('User ID (before decoding):', options.user.id)
//         console.log('formatted options', options)
//         // Convert to Uint8Array
//         // const challengeArray = convertToUint8Array(challengeBytes)
//         // const userIDArray = convertToUint8Array(userIDBytes)
//         // Start WebAuthn registration

//         const attestation = await startRegistration(options)
//         console.log('attestation', attestation)

//         // Send the attestation to the backend for verification
//         const verifyBody = { data: attestation, userID }
//         const verify = await apiClient.post('/webauthn/register/verify', verifyBody)

//         console.log('verify', verify)

//         return verify
//     } catch (error) {
//         console.error('Error during registration:', error)
//         throw error
//     }
// }

// export const registerTest = async (userID, username) => {
//     const body = { userID, username }
//     console.log('body:', body)

//     try {
//         // Step 1: Request registration options from the backend
//         const response = await apiClient.post('/webauthn/register/options', body)
//         const options = response.data
//         console.log('Options received from backend:', options)
   
//          // Step 2: Convert challenge and user.id to Uint8Array (modified)
//          if (!options || !options.challenge) {
//             throw new Error("Invalid registration options: challenge is missing.");
//           }

//          const challenge = options.challenge ? Uint8Array.from(atob(options.challenge), (c) => c.charCodeAt(0)) : null;
//          options.user.id = Uint8Array.from(atob(options.user.id || ''), (c) => c.charCodeAt(0));
//          console.log('Formatted options:', options);
 
//          // ... existing code (modify validation step similar to challenge)
//          if (!challenge || !(challenge instanceof Uint8Array)) {
//          throw new Error('Invalid or missing challenge.');
//          }
//         if (!options.user || !options.user.id || !(options.user.id instanceof Uint8Array)) {
//             throw new Error('Invalid or missing user.id.')
//         }
//         if (!options.rp || !options.rp.name || !options.rp.id) {
//             throw new Error('Invalid or missing relying party info.')
//         }

       
//         // Step 4: Start WebAuthn registration
//         const attestation = await startRegistration(options)
//         console.log('Attestation:', attestation)

//         // Step 5: Send attestation to backend for verification
//         const verifyBody = { data: attestation, userID }
//         const verify = await apiClient.post('/webauthn/register/verify', verifyBody)
//         console.log('Verify:', verify)

//         return verify
//     } catch (error) {
//         console.error('Error during registration:', error)
//         throw error
//     }
// }


export const registerTest = async (userID, username) => {
    const body = { userID, username };
    console.log('Request body:', body);

    try {
        // Step 1: Request registration options from the backend
        const response = await apiClient.post('/webauthn/register/options', body);
        console.log('Options received from backend:', response.data);

        const options = response.data;

        // Validate the challenge and other required properties
        if (!options || !options.challenge) {
            throw new Error('Invalid registration options: challenge is missing.');
        }

        options.challenge = Uint8Array.from(atob(options.challenge), (c) => c.charCodeAt(0));
        options.user.id = Uint8Array.from(atob(options.user.id), (c) => c.charCodeAt(0));
        
        console.log('Formatted options:', options);

        // Step 3: Start WebAuthn registration
        const attestation = await startRegistration(options);
        console.log('Attestation:', attestation);

        // Step 4: Send attestation to backend for verification
        const verifyBody = { data: attestation, userID };
        const verifyResponse = await apiClient.post('/webauthn/register/verify', verifyBody);
        console.log('Verification response:', verifyResponse);

        return verifyResponse.data;
    } catch (error) {
        console.error('Error during registration:', error);
        throw error;
    }
};



function convertToUint8Array(byteObject) {
    const byteArray = Object.values(byteObject)
    return new Uint8Array(byteArray)
}

// export const createOTAttendance = async (attendance) => {
//     try {
//         const response = await apiClient.post('/overtime/create', attendance)
//         return response.data
//     } catch (error) {
//         let errorMessage = 'Unexpected error occurred'

//         if (axios.isAxiosError(error)) {
//             if (error.response) {
//                 // Error response from backend
//                 errorMessage = error.response.data?.message || 'Unexpected error occurred.'
//             } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
//                 // Handle connection refused explicitly
//                 errorMessage = 'Connection refused. Please check the backend server.'
//             }
//             console.error('Axios error:', error.response?.status, error.response?.data)
//         } else {
//             console.error('Unexpected error:', error)
//             errorMessage = 'An unexpected error occurred: ' + error.message
//         }

//         return { error: errorMessage } // Return error message in an object
//     }
// }

// Authenticate
export const login = async () => {
    const response = await fetch('/webauthn/auth/options', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    })
    const options = await response.json()

    const assertion = await startAuthentication(options)

    const verifyResponse = await fetch('/webauthn/auth/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(assertion)
    })

    const { verified } = await verifyResponse.json()
    return verified
}
