import React, { useState, useContext } from 'react'
import ASC_LOGO from '../assets/asc-logo.jpg'
import ASC_MONOGRAM from '../assets/asc-monogram.jpg'
import DialogHelper from '../helper/DialogHelper'
import { useNavigate } from 'react-router-dom'
import { login } from '../services/AuthService'
import { UserContext } from '../helper/routes/UserContext'

export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [dialogMessage, setDialogMessage] = useState('')
    const navigate = useNavigate()
    const { setUserRole } = useContext(UserContext) // Access setUserRole from context

    const handleSubmit = async (event) => {
        event.preventDefault()
        setLoading(true)
        setError(null)

        const credentials = { username: email, password }
        const result = await login(credentials)

        if (result.error) {
            setDialogMessage(result.error)
            setIsOpen(true)
        } else {
            //   setDialogMessage('Login successful! Redirecting...');
            //   setIsOpen(true);
            setUserRole(result.role)
            setTimeout(() => {
                navigate('/')
            }, 1000)
        }

        setLoading(false)
    }

    return (
        <div className="flex h-screen">
            <div
                className="w-1/2 h-full"
                style={{
                    backgroundImage: `url(${ASC_MONOGRAM})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }}
            ></div>

            <div className="w-1/2 flex items-center justify-center">
                <div className="bg-white p-8 shadow-lg rounded-lg min-w-[450px] px-8">
                    <div className="flex justify-center mb-8">
                        <img src={ASC_LOGO} alt="Logo" className="h-20" />
                    </div>

                    <div className="mb-8">
                        <h1 className="text-3xl text-center font-semibold">Login</h1> {/* Centered Login Header */}
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="mb-5">
                                <label className="font-medium mb-2 flex">Email</label>
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Enter your email"
                                    className="w-full border rounded-md bg-transparent border-gray-400 p-3"
                                />
                            </div>
                            <div className="mb-5">
                                <label className="font-medium mb-2 flex">Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                    placeholder="Enter your password"
                                    className="w-full border rounded-md bg-transparent border-gray-400 p-3"
                                />
                            </div>

                            <div className="mb-5 flex justify-end">
                                <span className="text-red-600 cursor-pointer">Forgot Password</span>{' '}
                                {/* Add style for the forgot password link */}
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className="block bg-red-600 hover:bg-red-700 text-white w-full py-2 px-8 rounded"
                            >
                                {loading ? 'Logging in...' : 'Login'}
                            </button>
                            {error && <p>{error}</p>}
                        </div>
                    </form>
                </div>
            </div>

            {/* Dialog Box Implementation */}
            <DialogHelper isOpen={isOpen} onClose={() => setIsOpen(false)} title="Failed" message={dialogMessage} />
        </div>
    )
}
