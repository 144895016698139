import React from 'react';
import { Dialog, DialogPanel, DialogTitle, Description } from '@headlessui/react';

export default function DialogHelper({ isOpen, onClose, title, message }) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <DialogPanel className="max-w-lg space-y-4 border bg-white p-12">
          <DialogTitle className="font-bold">{title}</DialogTitle>
          <Description>{message}</Description>
          <div className="flex gap-4 justify-end">
            <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded">Close</button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}



