import React, { createContext, useState,useEffect } from 'react';


export const UserContext = createContext();


export const UserProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(null); 


    useEffect(() => {
        const storedRole = localStorage.getItem('userRole');
        if (storedRole) {
            setUserRole(storedRole);
        }
    }, []);

    return (
        <UserContext.Provider value={{ userRole, setUserRole }}>
            {children}
        </UserContext.Provider>
    );
};
