import axios from 'axios'
import apiClient from '../clients/ApiClient' // Update this import path as needed

/**
 * login - Function to handle the login API request.
 *
 * @param {object} credentials - Object containing username and password.
 * @returns {Promise<object>} - The response data from the API.
 * @throws {Error} - Throws an error if the login fails.
 */
export const login = async (credentials) => {
    try {
        const response = await apiClient.post('/auth/login', credentials)
        localStorage.setItem('authToken', response.data.access_token)
        localStorage.setItem('userRole', response.data.role)
        localStorage.setItem('timeIn', response.data.timeIn)
        localStorage.setItem('timeOut', response.data.timeOut)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                // Error response from backend
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                // Handle connection refused explicitly
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}
