import axios from 'axios'
import apiClient from '../clients/ApiClient' // Update this import path as needed

/**
 * Function to handle the attendance API request.
 * @param {object} attendance - Object containing employeeId and transactionType and remarks.
 * @param {string} employeeId - Employee Id.
 * @param {int} transactionType - Indetifier 1 = TIME IN | 2 = TIME OUT.
 * @param {int} remarks - Indetifier 1 = HOME | 2 = OFFICE.
 * @returns {Promise<object>} - The response data from the API.
 * @throws {Error} - Throws an error if the login fails.
 */
export const fetchUserRoles = async (filter) => {
    try {
        const response = await apiClient.get('/roles/', { params: filter })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const createEmployee = async (employeeDetails) => {
    try {
        const response = await apiClient.post('/employees/create', employeeDetails)
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}

export const getActiveUserRole = async (filter) => {
    try {
        const response = await apiClient.get('/roles/active', { params: filter })
        return response.data
    } catch (error) {
        let errorMessage = 'Unexpected error occurred'

        if (axios.isAxiosError(error)) {
            if (error.response) {
                errorMessage = error.response.data?.message || 'Unexpected error occurred.'
            } else if (error.message.includes('ERR_CONNECTION_REFUSED')) {
                errorMessage = 'Connection refused. Please check the backend server.'
            }
            console.error('Axios error:', error.response?.status, error.response?.data)
        } else {
            console.error('Unexpected error:', error)
            errorMessage = 'An unexpected error occurred: ' + error.message
        }

        return { error: errorMessage } // Return error message in an object
    }
}
