import React from 'react';
import { useNavigate } from 'react-router-dom';
import ASCLOGO from '../assets/asc-logo.jpg';

export default function Unauthorized() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/login'); // Redirect to login
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
            <div className="max-w-md text-center bg-white p-6 rounded-lg shadow-lg">
                <img src={ASCLOGO} alt="Unauthorized" className="mx-auto mb-4 h-48 w-48"/>
                <h1 className="text-4xl font-bold text-blue-600 mb-2">Hold Up!</h1>
                <h2 className="text-xl font-semibold text-gray-600">Error 401: Unauthorized</h2>
                <p className="text-gray-500 mb-4">You don't have permission to access this page.</p>
                <button
                    onClick={handleGoBack}
                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
                >
                    Go to Login
                </button>
            </div>
        </div>
    );
}
