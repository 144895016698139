import React, { useState } from 'react'
import { login, registerTest } from '../../services/WebAuthService'

function WebAuthApp() {
    const [userID, setUserID] = useState('')
    const [username, setUsername] = useState('')

    const handleRegister = () => {
        registerTest(userID, username).then(() => alert('Registered successfully!'))
    }

    const handleLogin = () => {
        login().then((verified) => {
            alert(verified ? 'Login successful!' : 'Login failed')
        })
    }

    return (
        <div>
            <h1>WebAuthn Demo</h1>
            <div>
                <input type="text" placeholder="User ID" value={userID} onChange={(e) => setUserID(e.target.value)} />
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <button onClick={handleRegister}>Register</button>
            </div>
            <button onClick={handleLogin}>Login</button>
        </div>
    )
}

export default WebAuthApp
