import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { fetchAttendance, fetchOvertimeAttendance } from '../../../services/AttendanceService'

const AttendanceLayout = () => {
    const [dtr, setDTR] = useState([]) // State to store employee data
    const [loading, setLoading] = useState(false) // Loading state
    const [dateStart, setDateStart] = useState('')
    const [dateEnd, setDateEnd] = useState('')
    const [recordType, setRecordType] = useState('regular') // State for record type

    // Example data structure for DataGrid
    const columns = [
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'timeIn', headerName: 'Time In', width: 150 },
        { field: 'timeOut', headerName: 'Time Out', width: 150 },
        {
            field: 'remarks',
            headerName: 'Working At',
            width: 150,
            renderCell: (params) => {
                const remark = params.value
                const bgColor = remark === 'Home' ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'
                return <span className={`px-3 py-1 rounded-full font-semibold ${bgColor}`}>{remark}</span>
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: (params) => {
                const status = params.value

                // Determine background color based on status
                let bgColor = ''
                let textColor = ''

                switch (status) {
                    case 'Approved':
                        bgColor = 'bg-green-100'
                        textColor = 'text-green-700'
                        break
                    case 'Pending':
                        bgColor = 'bg-yellow-100'
                        textColor = 'text-yellow-700'
                        break
                    case 'Disapproved':
                        bgColor = 'bg-red-100'
                        textColor = 'text-red-700'
                        break
                }

                return <span className={`px-3 py-1 rounded-full font-semibold ${bgColor} ${textColor}`}>{status}</span>
            }
        },
        { field: 'reason', headerName: 'Reason', width: 250 }
    ]

    const fetchDtrData = async () => {
        // Validate the date range
        if (new Date(dateStart) > new Date(dateEnd)) {
            alert('The start date cannot be after the end date.')
            return
        }

        setLoading(true)

        try {
            const attendance = {
                dateStart: dateStart,
                dateEnd: dateEnd
            }

            let response = null
            console.log('recordType', recordType)
            if (recordType === 'regular') {
                response = await fetchAttendance(attendance)
                console.log('fetchAttendance', response)
            } else if (recordType === 'overtime') {
                response = await fetchOvertimeAttendance(attendance)
                console.log('fetchOvertimeAttendance', response)
            }
            console.log('fetchDtrData', response)
            const dailyRecords = response.dailyTimeRecord || []
            const formattedData = dailyRecords.map((record, index) => ({
                id: index + 1, // Generate unique ID for each record
                date: record.date,
                timeIn: record.timeIn ? new Date(record.timeIn).toLocaleTimeString() : '-',
                timeOut: record.timeOut ? new Date(record.timeOut).toLocaleTimeString() : '-',
                remarks: record.remarks || '-',
                totalHours: record.totalHours || '-',
                status: record.status || '-',
                reason: record.reason || '-'
            }))

            setDTR(formattedData)
            setLoading(false)
        } catch (error) {
            console.error('Error fetching DTR data:', error)
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="flex items-center justify-between w-full mb-4">
                <h1 className="text-xl font-bold text-black">Daily Time Records</h1>
            </div>

            {/* Date Range Selectors - Responsive */}
            <div className="flex items-center space-x-4 p-8">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Record Type</label>
                    <select
                        id="recordType"
                        value={recordType}
                        onChange={(e) => setRecordType(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-6 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                        <option value="regular">Regular</option>
                        <option value="overtime">Overtime</option>
                    </select>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Date Start</label>
                    <input
                        type="date"
                        id="dateStart"
                        placeholder="mm/dd/yyyy"
                        value={dateStart}
                        onChange={(e) => setDateStart(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                <div>
                    <label className="block text-sm font-medium text-gray-700">Date End</label>
                    <input
                        type="date"
                        id="dateEnd"
                        placeholder="mm/dd/yyyy"
                        value={dateEnd}
                        onChange={(e) => setDateEnd(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>

                <div className="mt-6">
                    <button
                        type="submit"
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-60"
                        onClick={fetchDtrData}
                    >
                        Fetch
                    </button>
                </div>
            </div>

            {/* Data Table */}
            <div className="w-full" style={{ height: 500, maxWidth: 1050 }}>
                <DataGrid
                    className="bg-white"
                    rows={dtr}
                    columns={columns.map((column) => ({
                        ...column,
                        headerClassName: 'header-bold'
                    }))}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    loading={loading}
                    disableSelectionOnClick
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            fontWeight: 'bold'
                        },
                        '& .MuiDataGrid-cell': {
                            cursor: 'default',
                            pointerEvents: 'none'
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default AttendanceLayout
