import React from "react";
import OTAttendanceTodayList from "./OTAttendanceTodayList";
import OTAttendanceActions from "./OTAttendanceActions";

export default function OTAttendanceManager() {
    return (
        <div className="min-h-screen flex flex-col lg:flex-row">
            {/* Left Container */}
            <div className="lg:w-1/2 w-full bg-gray-100 p-4">
                <OTAttendanceTodayList />
            </div>
            {/* Right Container */}
            <div className="lg:w-1/2 w-full bg-white p-4">
                <OTAttendanceActions />
            </div>
        </div>
    );
}
