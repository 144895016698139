import React from "react";
import AttendanceTodayList from "./AttendanceTodayList";
import AttendanceActions from "./AttendanceActions";

export default function AttendanceManager() {
    return (
        <div className="min-h-screen flex flex-col lg:flex-row">
            {/* Left Container */}
            <div className="lg:w-1/2 w-full bg-gray-100 p-4">
                <AttendanceTodayList />
            </div>
            {/* Right Container */}
            <div className="lg:w-1/2 w-full bg-white p-4">
                <AttendanceActions />
            </div>
        </div>
    );
}
