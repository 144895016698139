// src/components/QRScannerModal.jsx
import React, { useRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import Webcam from 'react-webcam'
import jsQR from 'jsqr'

const QRScannerModal = ({ onCancel, onScan }) => {
    const webcamRef = useRef(null)
    const canvasRef = useRef(null)
    const scanInterval = useRef(null)

    useEffect(() => {
        // Function to perform scanning
        const scanQRCode = () => {
            if (webcamRef.current) {
                const screenshot = webcamRef.current.getScreenshot()
                if (screenshot) {
                    const image = new Image()
                    image.src = screenshot
                    image.onload = () => {
                        const canvas = canvasRef.current
                        const context = canvas.getContext('2d')
                        canvas.width = image.width
                        canvas.height = image.height
                        context.drawImage(image, 0, 0, canvas.width, canvas.height)
                        const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
                        const code = jsQR(imageData.data, imageData.width, imageData.height, {
                            inversionAttempts: 'dontInvert'
                        })
                        if (code) {
                            console.log('QR Code detected:', code.data)
                            onScan(code.data)
                            stopScanning()
                        }
                    }
                }
            }
        }

        // Start scanning at intervals (e.g., every 1000ms)
        scanInterval.current = setInterval(scanQRCode, 1000)

        // Cleanup function to stop scanning
        return () => {
            clearInterval(scanInterval.current)
        }
    }, [onScan])

    // Function to stop scanning
    const stopScanning = () => {
        clearInterval(scanInterval.current)
    }

    // Ensure modal is rendered in the DOM
    if (typeof document === 'undefined') {
        return null
    }

    return ReactDOM.createPortal(
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="relative bg-white p-6 rounded-lg w-[400px] h-[450px] flex flex-col items-center">
                <h2 className="text-xl mb-2">Scan QR Code</h2>
                <p className="text-sm text-gray-600 mb-4">Align the QR code within the frame to scan.</p>

                <button
                    onClick={() => {
                        onCancel()
                        stopScanning()
                    }}
                    className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
                >
                    Cancel
                </button>

                <div className="relative w-full h-[300px] mt-4">
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                            facingMode: 'environment'
                        }}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                    {/* Overlay for guiding QR code placement */}
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
                        <div className="border-4 border-dashed border-white w-3/4 h-3/4"></div>
                    </div>
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                </div>

                {/* Optional: Display error messages */}
                {/* <div className="mt-2">
          {error && <p className="text-red-500 text-sm">{error}</p>}
        </div> */}
            </div>
        </div>,
        document.body
    )
}

export default QRScannerModal
