import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Stack,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormHelperText
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { createEmployee, getActiveEmployees } from '../../services/EmployeeService'
import { getActiveUserRole } from '../../services/UserRoleService'
import { createUserAccount } from '../../services/UserAccountService'

const UserAccountRegisterModal = ({ open, onClose, onRegisterSuccess }) => {
    const [errors, setErrors] = useState({})
    const [responseMessage, setResponseMessage] = useState('')
    const [showResponseDialog, setShowResponseDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [newEmployee, setNewEmployee] = useState({})

    const [roles, setRoles] = useState([])

    useEffect(() => {
        const loadRoles = async () => {
            try {
                const result = await getActiveUserRole()
                console.log('loadRoles', result)
                setRoles(result || [])
            } catch (error) {
                console.error('Error fetching roles:', error)
            }
        }

        loadRoles()
    }, [])

    const [employees, setEmployees] = useState([])

    useEffect(() => {
        const loadEmployees = async () => {
            try {
                const result = await getActiveEmployees()
                console.log('loadEmployees', result)
                setEmployees(result || [])
            } catch (error) {
                console.error('Error fetching roles:', error)
            }
        }

        loadEmployees()
    }, [])

    const validateFields = () => {
        const newErrors = {}
        const requiredFields = ['employeeId', 'username', 'userRoleId']

        requiredFields.forEach((field) => {
            if (!newEmployee[field]) {
                newErrors[field] = 'This field is required'
            }
        })

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0
    }

    const handleRegisterInputChange = (e) => {
        const { name, value } = e.target

        setNewEmployee((prev) => {
            const updatedEmployee = { ...prev, [name]: value }

            if (name === 'employeeId') {
                const selectedEmployee = employees.find((emp) => emp.employeeId === value)
                if (selectedEmployee) {
                    const firstName = selectedEmployee.firstName.toLowerCase().replace(/\s+/g, '')
                    const lastName = selectedEmployee.lastName.toLowerCase().replace(/\s+/g, '')
                    updatedEmployee.username = `${firstName}.${lastName}@asc.com.ph`
                }
            }

            return updatedEmployee
        })
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault()

        if (validateFields()) {
            try {
                const result = await createUserAccount(newEmployee)

                if (result.error) {
                    setResponseMessage(result.error)
                } else {
                    setResponseMessage(result.message)
                    onRegisterSuccess()
                }
                setShowResponseDialog(true)
            } catch (error) {
                setResponseMessage('An error occurred while recording attendance.')
                setShowResponseDialog(true)
            } finally {
                setLoading(false)
                onClose()
            }
        }
    }

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>Add Account</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleFormSubmit}>
                        <Stack spacing={3}>
                            {/* Form fields */}

                            <FormControl
                                style={{ marginTop: '16px' }}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                error={Boolean(errors.employeeId)}
                            >
                                <InputLabel>Employee</InputLabel>
                                <Select
                                    name="employeeId"
                                    value={newEmployee.employeeId}
                                    onChange={handleRegisterInputChange}
                                    label="Employee"
                                >
                                    {employees.length > 0 ? (
                                        employees.map((employee) => (
                                            <MenuItem key={employee.id} value={employee.employeeId}>
                                                {employee.firstName} {employee.lastName}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No employee available</MenuItem>
                                    )}
                                </Select>
                                {errors.employeeId && <FormHelperText>{errors.employeeId}</FormHelperText>}
                            </FormControl>

                            <TextField
                                margin="normal"
                                name="username"
                                label="ASC Email"
                                fullWidth
                                variant="outlined"
                                value={newEmployee.username}
                                onChange={handleRegisterInputChange}
                                placeholder="Generated email will appear here"
                                readOnly // Set the field to read-only if you don't want it editable
                                sx={{
                                    '& .MuiInputLabel-root': {
                                        transform: newEmployee.username
                                            ? 'translate(14px, -9px) scale(0.75)'
                                            : 'translate(14px, 20px) scale(1)',
                                        transition: 'transform 0.2s ease-in-out'
                                    },
                                    '& .MuiInputBase-input': {
                                        paddingTop: newEmployee.username ? '16px' : '18px' // Adjusts padding when value exists
                                    }
                                }}
                            />

                            {/* Role Field - Dynamically Fetched from API */}
                            <FormControl
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                error={Boolean(errors.userRoleId)}
                            >
                                <InputLabel>Role</InputLabel>
                                <Select
                                    name="userRoleId"
                                    value={newEmployee.userRoleId}
                                    onChange={handleRegisterInputChange}
                                    label="Role"
                                >
                                    {roles.length > 0 ? (
                                        roles.map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.role}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem disabled>No roles available</MenuItem>
                                    )}
                                </Select>
                                {errors.userRoleId && <FormHelperText>{errors.userRoleId}</FormHelperText>}
                            </FormControl>
                        </Stack>

                        <DialogActions style={{ marginTop: '16px' }}>
                            <Button onClick={onClose} color="secondary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                Register
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Response Dialog */}
            <Dialog open={showResponseDialog} onClose={() => setShowResponseDialog(false)}>
                <DialogTitle>Response</DialogTitle>
                <DialogContent>
                    <p>{responseMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowResponseDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UserAccountRegisterModal
